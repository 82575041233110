export const prodUrl = 'https://api-erasmus.dbima.fr';
// export const prodUrl = 'http://localhost:8085';
export const loginUrl = '/user/login?type=1';
export const registerUser = '/user/register';
export const deleteUser = '/user/delete?id=';
export const editUser = '/user/update';
export const getUser = '/user/get?id=';
export const getAllCourses = '/course/all';
export const registerCourse = '/course/save';
export const registerTutorial = '/course/tutorial/save';
export const quizTableUrl = '/quiz/getAll?searchParam=';
export const getAllCourseUrl = '/course/getAllByExamStatus';
export const getCourseDetails = '/course/search?courseId=';
export const getPartsByTutorial = '/course/getAllByQuizStatus?tutorialId=';
export const registerQuiz = '/quiz/save';
export const getProgressTableData = '/progress/getProgressByCourse';
export const getProgressUserData = '/progress/getProgressByUser';
export const updateProfileUrl = '/user/update';
export const changePasswordUrl = '/user/changePassword';
export const deleteCourse = '/course/delete?id=';
export const editCourse = '/course/edit';
export const deleteTutorial = '/tutorial/delete?id=';
export const getTutorialDashboardData = '/tutorial/dashboard?id=';
export const editTutorial = '/tutorial/edit';
export const deletePart = '/part/delete?id=';
export const editPart = '/part/edit';
export const registerPart = '/course/part/save';
export const deleteQuizByPart = '/quiz/deleteByPart?type=';
export const getQuizByPart = '/quiz/getAllByPartName?type=';
export const deleteQuiz = '/quiz/delete?id=';
export const editQuiz = '/quiz/edit';
export const markingUrl = '/progress/updateResult?userId=';
export const getAllFeedbackUrl = '/feedback/getTableData';
export const fileUploadUrl = '/upload';
export const dashboardDataUrl = '/dashboard/stats';
export const getAllUsers = '/user/all?searchParam=';
export const getAllCertificate = '/certificate/get?searchParam=';
export const profileValidateUrl = '/profile/validate?id=';
export const printUrl = '/certificate?userId=';
export const verifyFeedbackUrl = '/feedback/approve?id=';
export const deleteFeedbackUrl = '/feedback/delete?id=';
export const getAllContacts = '/contact/getAll';
export const deleteContact = '/contact/delete?id=';
export const getAllNews = '/news/findAll';
export const publishNews = '/news/send';
export const forgotPassword = '/user/forgot?username=';
export const validateOtp = '/validate/otp?otp=';
