import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {UserService} from '../../../service/user-service';
import {User} from '../../../model/User';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-tbl-basic',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  user: User;
  updateUserForm: any;
  data: User = new User();

  constructor(private snackBar: MatSnackBar, private userService: UserService, private dialog: MatDialog) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.updateUserForm = new FormGroup({
      first: new FormControl(this.user.firstName),
      last: new FormControl(this.user.lastName),
      email: new FormControl(this.user.email),
      phone: new FormControl(this.user.phoneNumber),
      username: new FormControl(this.user.username)
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 1000
    });
  }

  updateProfile() {
    this.openSnackBar('Please Wait...');
    this.data.username = this.updateUserForm.value.username;
    this.data.phoneNumber = this.updateUserForm.value.phone;
    this.data.email = this.updateUserForm.value.email;
    this.data.firstName = this.updateUserForm.value.first;
    this.data.lastName = this.updateUserForm.value.last;
    this.data.password = this.user.password;
    this.data.type = this.user.type;
    this.data.id = this.user.id;
    this.data.dob = '';
    this.data.country = this.user.country;
    this.userService.updateProfile(this.data).subscribe(response => {
      if (response.status === 1) {
        this.openSnackBar(response.message);
        localStorage.setItem('user', JSON.stringify(this.user));
        this.dialog.closeAll();
      } else {
        this.openSnackBar('Your request can\'t be completed right now.');
      }
    }, error => {
      console.log(error);
      this.openSnackBar('Your request can\'t be completed right now.');
    });
  }
}
