import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DxVectorMapModule, DxPieChartModule } from 'devextreme-angular';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';

import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {UserProfileComponent} from '../pages/authentication/user-profile/user-profile.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ChangePasswordComponent} from '../pages/authentication/change-password/change-password.component';

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule, FormsModule, JwBootstrapSwitchNg2Module, NgbModule, DxVectorMapModule,
    DxPieChartModule, MatDialogModule, ReactiveFormsModule, MatInputModule, MatButtonModule, MatSnackBarModule, MatDialogModule],
  declarations: [
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
      UserProfileComponent,
      ChangePasswordComponent
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
  ],
  entryComponents: [UserProfileComponent, ChangePasswordComponent]
})
export class ComponentsModule {}
