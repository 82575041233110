import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiResponse} from '../model/api-response';
import {
    deleteContact,
    deleteFeedbackUrl,
    getAllContacts,
    getAllFeedbackUrl,
    getAllNews,
    prodUrl, publishNews,
    verifyFeedbackUrl
} from '../model/endpoints';
import {NewsSentDto} from '../model/news-sent-dto';

@Injectable({
    providedIn: 'root'
})

export class FeedbackService {

    constructor(private http: HttpClient) {}

    getAllFeedbacks() {
        // tslint:disable-next-line:max-line-length
        return this.http.get<ApiResponse>(prodUrl + getAllFeedbackUrl, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('token'))});
    }

    verifyFeedback(id: number) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<ApiResponse>(prodUrl + verifyFeedbackUrl + id, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('token'))});
    }

    deleteFeedback(id: number) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<ApiResponse>(prodUrl + deleteFeedbackUrl + id, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('token'))});
    }

    getAllContactRequests() {
        // tslint:disable-next-line:max-line-length
        return this.http.get<ApiResponse>(prodUrl + getAllContacts, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('token'))});
    }

    deleteContactRequest(id: number) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<ApiResponse>(prodUrl + deleteContact + id, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('token'))});
    }

    getAllNews() {
        return this.http.get<ApiResponse>(prodUrl + getAllNews, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('token'))});
    }

    publishNews(data: NewsSentDto) {
        // tslint:disable-next-line:max-line-length
        return this.http.post<ApiResponse>(prodUrl + publishNews, data, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('token'))});
    }

}
