import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiResponse} from '../model/api-response';
import {Authentication} from '../model/authentication';
import {
    changePasswordUrl,
    dashboardDataUrl, deleteUser, editUser, forgotPassword,
    getAllUsers, getUser,
    loginUrl,
    prodUrl,
    profileValidateUrl, registerUser,
    updateProfileUrl, validateOtp
} from '../model/endpoints';
import {User} from '../model/User';
import {ChangePasswordDto} from '../model/changePasswordDto';

@Injectable({
  providedIn: 'root'
})
// tslint:disable-next-line:class-name
export class UserService {

  constructor(private http: HttpClient) {}

  loginUser(auth: Authentication) {
    return this.http.post<ApiResponse>(prodUrl + loginUrl, auth);
  }

  updateProfile(user: User) {
    // tslint:disable-next-line:max-line-length
    return this.http.post<ApiResponse>(prodUrl + updateProfileUrl, user, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('token'))});
  }

  changePassword(data: ChangePasswordDto) {
    return this.http.post<ApiResponse>(prodUrl + changePasswordUrl, data);
  }

  getStats() {
    return this.http.get<ApiResponse>(prodUrl + dashboardDataUrl, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('token'))});
  }

  getAllUsers(email: string) {
    return this.http.get<ApiResponse>(prodUrl + getAllUsers + email, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('token'))});
  }

  validateProfile(id: number) {
    return this.http.get<ApiResponse>(prodUrl + profileValidateUrl + id, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('token'))});
  }

  registerUser(data: User) {
    return this.http.post<ApiResponse>(prodUrl + registerUser , data, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('token'))});
  }

 deleteUser(id: any) {
    return this.http.get<ApiResponse>(prodUrl + deleteUser + id, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('token'))});
  }

  editUser(data: User) {
    return this.http.post<ApiResponse>(prodUrl + editUser , data, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('token'))});
  }

  getUser(id: any) {
    return this.http.get<ApiResponse>(prodUrl + getUser + id, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('token'))});
  }

  forgotUser(username: any) {
    return this.http.get<ApiResponse>(prodUrl + forgotPassword + username);
  }

    validateOtp(username: string, otp: string) {
        return this.http.get<ApiResponse>(prodUrl + validateOtp + otp + '&username=' + username);
    }
}
