export class Quiz {
    id: number;
    courseID: number;
    tutorialId: number;
    partId: number;
    type: any;
    question: string;
    answer01: string;
    answer02: string;
    answer03: string;
    answer04: string;
    answerSequence: string;
    languageCode: string;
    created: string;
}
